import { useEffect, useState } from 'react';
import { Price, Trade } from '../types';
import { supabase } from '../supabaseClient';

export const useTrades = () => {
  const [trades, setTrades] = useState<Trade[]>([]);

  const fetchTrades = async () => {
    const { data, error } = await supabase
      .from('trades')
      .select('*')
      .order('id', { ascending: false });

    if (error) {
      console.error('Error fetching trades:', error);
    } else if (data) {
      setTrades(data as Trade[]);
    }
  };

  const addTrade = async (trade: Omit<Trade, 'id' | 'datetime'>) => {
    const { data, error } = await supabase
      .from('trades')
      .insert([{ ...trade, datetime: new Date().toISOString() }])
      .select();  // Add .select() to make sure Supabase returns the inserted data

    if (error) {
      console.error('Error adding trade:', error);
    } else if (data && data.length > 0) {  // Ensure data is not null and contains items
      setTrades((prevTrades) => [data[0] as Trade, ...prevTrades]);
    }
  };

  const deleteTrade = async (tradeId: number) => {
    const { error } = await supabase
      .from('trades')
      .delete()
      .eq('id', tradeId);

    if (error) {
      console.error('Error deleting trade:', error);
    } else {
      setTrades((prevTrades) => prevTrades.filter((trade) => trade.id !== tradeId));
    }
  };

  useEffect(() => {
    fetchTrades();
  }, []);

  return { trades, addTrade, deleteTrade };
};

export const usePrices = () => {
  const [prices, setPrices] = useState<Price[]>([]);

  const fetchPrices = async () => {
    const { data, error } = await supabase
      .from('prices')
      .select('*');

    if (error) {
      console.error('Error fetching prices:', error);
    } else if (data) {
      setPrices(data as Price[]);
    }
  };

  useEffect(() => {
    fetchPrices();

    const taskListener = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "prices" },
        () => {
          fetchPrices();
        }
      )
      .subscribe();

    return () => {
      taskListener.unsubscribe()
    };
  }, []);

  return { prices };
};