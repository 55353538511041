import React from 'react';

interface NavbarProps {
  onBuy: () => void;
  onSell: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onBuy, onSell }) => (
  <nav className="navbar">
    <button className="buy" onClick={onBuy}>Buy</button>
    <button className="sell" onClick={onSell}>Sell</button>
  </nav>
);

export default Navbar;
