import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Price, Trade } from '../types';

interface TradeDetailsProps {
  trades: Trade[];
  prices: Price[];
  deleteTrade: (tradeId: number) => Promise<void>;
}

const TradeDetails: React.FC<TradeDetailsProps> = ({ trades, deleteTrade, prices }) => {
  const { tradeId } = useParams<{ tradeId: string }>();
  const navigate = useNavigate();
  const trade = trades.find((t) => t.id === Number.parseInt(tradeId + ''));
  const currentPrice = prices.find(price => price.base_asset === trade?.base_asset && price.quote_asset === trade?.quote_asset);

  if (!trade) {
    return <p>Trade not found.</p>;
  }

  const handleDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this trade?");
    if (confirmed) {
      await deleteTrade(trade.id);
      setTimeout(() => navigate('/'), 0);
    }
  };


  return (
    <div className="trade-details">
      <h2>Trade Details</h2>
      <p><strong>Type:</strong> {trade.type}</p>
      <p><strong>Base Asset:</strong> {trade.base_asset}</p>
      <p><strong>Quote Asset:</strong> {trade.quote_asset}</p>
      <p><strong>Base Quantity:</strong> {trade.base_quantity}</p>
      <p><strong>Quote Price:</strong> {trade.quote_price}</p>
      <p><strong>Status:</strong> {trade.status}</p>
      <p><strong>Date:</strong> {new Date(trade.datetime).toLocaleString()}</p>
      {currentPrice &&
        <p><strong>Current price:</strong> {currentPrice.last_bid}</p>
      }
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
      <button onClick={handleDelete} className="delete-button">Delete</button>
    </div>
  );
};

export default TradeDetails;
