import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import TradeForm from './components/TradeForm';
import TradeList from './components/TradeList';
import TradeDetails from './components/TradeDetails';
import { usePrices, useTrades } from './hooks/useSupabase';
import './App.css';

const App: React.FC = () => {
  const { trades, addTrade, deleteTrade } = useTrades();
  const { prices } = usePrices();
  const [showForm, setShowForm] = useState<'buy' | 'sell' | null>(null);

  return (
    <Router>
      <div className="container">
        <Navbar onBuy={() => setShowForm('buy')} onSell={() => setShowForm('sell')} />
        {showForm && (
          <TradeForm
            type={showForm}
            onSubmit={(tradeData) => {
              addTrade(tradeData);
              setShowForm(null);
            }}
            onCancel={() => setShowForm(null)}
          />
        )}
        <Routes>
          <Route path="/" element={<TradeList trades={trades} prices={prices} />} />
          <Route path="/trade/:tradeId" element={<TradeDetails trades={trades} deleteTrade={deleteTrade} prices={prices} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
