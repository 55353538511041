import React, { useState } from 'react';
import { Trade } from '../types';

interface TradeFormProps {
  onSubmit: (trade: Omit<Trade, 'id' | 'datetime'>) => void;
  onCancel: () => void;
  type: 'buy' | 'sell';
}

const TradeForm: React.FC<TradeFormProps> = ({ onSubmit, onCancel, type }) => {
  const [baseAsset, setBaseAsset] = useState('BTC');
  const [quoteAsset, setQuoteAsset] = useState('USD');
  const [baseQuantity, setBaseQuantity] = useState(0);
  const [quotePrice, setQuotePrice] = useState(0);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      type,
      base_asset: baseAsset,
      quote_asset: quoteAsset,
      base_quantity: baseQuantity,
      quote_price: quotePrice,
      status: 'open'
    });
  };

  return (
    <div className="form-container">
      <h2>{type === 'buy' ? 'Buy' : 'Sell'} Trade</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Base Asset
          <input type="text" value={baseAsset} onChange={(e) => setBaseAsset(e.target.value)} required />
        </label>
        <label>
          Quote Asset
          <input type="text" value={quoteAsset} onChange={(e) => setQuoteAsset(e.target.value)} required />
        </label>
        <label>
          Base Quantity
          <input type="number" value={baseQuantity} onChange={(e) => setBaseQuantity(+e.target.value)} required />
        </label>
        <label>
          Quote Price
          <input type="number" value={quotePrice} onChange={(e) => setQuotePrice(+e.target.value)} required />
        </label>
        <div>
          <button type="submit" className={type === 'buy' ? 'buy' : 'sell'}>Submit {type}</button>
          <button type="button" onClick={onCancel} className="cancel">Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default TradeForm;
