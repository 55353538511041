import React from 'react';
import { Link } from 'react-router-dom';
import { Price, Trade } from '../types';

interface TradeListProps {
  trades: Trade[];
  prices: Price[];
}

const TradeList: React.FC<TradeListProps> = ({ trades, prices }) => (
  <div>
    <h2>Trade History</h2>
    <ul className="trade-list">
      {trades.map((trade) => {
        const currentPrice = prices.find(price => price.base_asset === trade.base_asset && price.quote_asset === trade.quote_asset);
        const profit = currentPrice ? ((currentPrice.last_bid - trade.quote_price) / trade.quote_price * 100).toFixed(2) : null;
        return (
          <Link key={trade.id} to={`/trade/${trade.id}`} className="trade-link">
            <li className="trade-item">
              <div className="trade-info">
                <p>{new Date(trade.datetime).toLocaleString()}</p>
                <p>{trade.status} {profit != null ? <span className={parseFloat(profit) >= 0 ? 'profit-positive' : 'profit-negative'}>({profit}%)</span> : ''}</p>
              </div>
              <p className="trade-type">
                {trade.type.toUpperCase()} {trade.base_asset} at {trade.quote_price} {trade.quote_asset}
              </p>
            </li>
          </Link>
        )
      })}
    </ul>
  </div>
);

export default TradeList;
